var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_setup.containerClassNameList},[(_setup.props.isMultipleSelectMode)?_c(_setup.RadioSelect,{staticClass:"multiple-select-radio",attrs:{"isSelected":_setup.isMultipleSelected},on:{"onChange":_setup.toggleMultipleSelect}}):_vm._e(),_c('div',{class:{
      'control-reverse': _setup.message.flow === 'out'
    }},[_c('div',{staticClass:"message-bubble-content"},[_c('div',{staticClass:"message-bubble-main-content",class:[_setup.message.flow === 'in' ? '' : 'reverse']},[_c(_setup.Avatar,{attrs:{"useSkeletonAnimation":"","url":_setup.message.avatar || ''}}),_c('main',{staticClass:"message-body",on:{"click":function($event){$event.stopPropagation();}}},[(_setup.message.flow === 'in' && _setup.message.conversationType === 'GROUP')?_c('div',{staticClass:"message-body-nick-name"},[_vm._v(" "+_vm._s(_setup.props.content.showName)+" ")]):_vm._e(),_c('div',{class:['message-body-main', _setup.message.flow === 'out' && 'message-body-main-reverse']},[_c('div',{class:[
                'blink',
                'message-body-content',
                _setup.message.flow === 'out' ? 'content-out' : 'content-in',
                _setup.message.hasRiskContent && 'content-has-risk',
                _setup.isNoPadding ? 'content-no-padding' : '',
                _setup.isNoPadding && _setup.isBlink ? 'blink-shadow' : '',
                !_setup.isNoPadding && _setup.isBlink ? 'blink-content' : '',
              ]},[_c('div',{staticClass:"content-main"},[(
                    (_setup.message.type === _setup.TYPES.MSG_IMAGE || _setup.message.type === _setup.TYPES.MSG_VIDEO) &&
                      _setup.message.hasRiskContent
                  )?_c('img',{class:['message-risk-replace', !_setup.isPC && 'message-risk-replace-h5'],attrs:{"src":_setup.riskImageReplaceUrl}}):[_vm._t("messageElement"),_vm._t("TUIEmojiPlugin")]],2),(_setup.message.hasRiskContent)?_c('div',{staticClass:"content-has-risk-tips"},[_vm._v(" "+_vm._s(_setup.riskContentText)+" ")]):_vm._e()]),(_setup.isDisplayUnplayMark)?_c('div',{staticClass:"audio-unplay-mark"}):_vm._e(),(_setup.message.status === 'fail' || _setup.message.hasRiskContent)?_c('div',{staticClass:"message-label fail",on:{"click":function($event){return _setup.resendMessage()}}},[_vm._v(" ! ")]):_vm._e(),(_setup.message.status === 'unSend' && _setup.needLoadingIconMessageType.includes(_setup.message.type))?_c(_setup.Icon,{staticClass:"message-label loading-circle",attrs:{"file":_setup.loadingIcon,"width":'15px',"height":'15px'}}):_vm._e(),_c(_setup.ReadStatus,{staticClass:"message-label align-self-bottom",attrs:{"message":_setup.shallowCopyMessage(_setup.message)},on:{"openReadUserPanel":_setup.openReadUserPanel}})],1)])],1),_c('div',{staticClass:"message-bubble-extra-content"},[_c(_setup.MessageTranslate,{class:_setup.message.flow === 'out' ? 'reverse' : 'flex-row',attrs:{"message":_setup.message}}),_c(_setup.MessageConvert,{class:_setup.message.flow === 'out' ? 'reverse' : 'flex-row',attrs:{"message":_setup.message}}),_c(_setup.MessageQuote,{class:_setup.message.flow === 'out' ? 'reverse' : 'flex-row',attrs:{"message":_setup.message},on:{"blinkMessage":_setup.blinkMessage,"scrollTo":_setup.scrollTo}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }