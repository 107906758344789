var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"message-translation-container",style:({
    height: _setup.calculateHeight > 0 ? `${_setup.calculateHeight}px` : 'auto',
    width: _setup.calculateWidth > 0 ? `${_setup.calculateWidth}px` : 'auto',
  })},[(_setup.translationFinished)?_c('div',{ref:"translationContentRef",class:{
      'translation-content': true,
      'occur': _setup.calculateHeight > 0,
    }},[(_setup.translationTextList.length > 0)?_vm._l((_setup.translationTextList),function(text,index){return _c('span',{key:index},[(text.type === 'face')?_c('img',{staticClass:"text-face",attrs:{"src":text.value}}):_c('span',{staticClass:"text-plain"},[_vm._v(_vm._s(text.value))])])}):[_vm._v(" "+_vm._s(_setup.translationErrorText)+" ")]],2):_vm._e(),_c('div',{ref:"translationLoadingRef",class:{
      'loading': true,
      'loading-end': _setup.translationFinished
    }},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t('TUIChat.翻译中'))+"... ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }