var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'tui-search-input-container',
    !_setup.isPC && 'tui-search-input-container-h5',
    _setup.props.searchType === 'global'
      ? 'tui-search-input-container-global'
      : 'tui-search-input-container-conversation'
  ]},[_c('div',{class:['tui-search-input', !_setup.isPC && 'tui-search-input-h5']},[_c('div',{staticClass:"tui-search-input-left"},[_c(_setup.Icon,{staticClass:"icon",attrs:{"file":_setup.searchIcon,"width":"14px","height":"14px"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.searchValueModel),expression:"searchValueModel"}],staticClass:"tui-search-input-main",attrs:{"type":"text","placeholder":_setup.props.placeholder,"focus":false,"enterkeyhint":"search"},domProps:{"value":(_setup.searchValueModel)},on:{"blur":_setup.onBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.search.apply(null, arguments)},"confirm":_setup.search,"click":function($event){$event.stopPropagation();$event.preventDefault();return _setup.onSearchInputClick.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_setup.searchValueModel=$event.target.value}}}),(_setup.searchingStatus)?_c('div',{staticClass:"tui-search-input-right",on:{"click":_setup.endSearching}},[_c(_setup.Icon,{staticClass:"icon",attrs:{"file":_setup.closeIcon,"width":"14px","height":"14px"}})],1):_vm._e()]),(!_setup.isPC && _setup.searchingStatus && _setup.props.searchType === 'global')?_c('div',{class:[
      'tui-search-input-cancel',
      !_setup.isPC && 'tui-search-input-h5-cancel',
    ],on:{"click":_setup.endSearching}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUISearch.取消"))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }