var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'mulitple-select-panel': true,
    'mulitple-select-panel-mobile': _setup.isMobile,
  }},[_c('div',{staticClass:"forward-button",on:{"click":_setup.oneByOneForwardMessage}},[_c(_setup.Icon,{attrs:{"file":_setup.ForwardEachIcon,"size":_setup.iconSize}}),_c('span',{class:{
        'forward-button-text': true,
        'forward-button-text-mobile': _setup.isMobile,
      }},[_vm._v(_vm._s(_setup.TUITranslateService.t('TUIChat.逐条转发')))])],1),_c('div',{staticClass:"forward-button",on:{"click":_setup.mergeForwardMessage}},[_c(_setup.Icon,{attrs:{"file":_setup.ForwardMergeIcon,"size":_setup.iconSize}}),_c('span',{class:{
        'forward-button-text': true,
        'forward-button-text-mobile': _setup.isMobile,
      }},[_vm._v(_vm._s(_setup.TUITranslateService.t('TUIChat.合并转发')))])],1),_c('div',{staticClass:"forward-button",on:{"click":_setup.cancelMultipleSelect}},[_c(_setup.Icon,{staticClass:"cancel-button-icon",attrs:{"file":_setup.AddIcon,"size":_setup.iconSize}}),_c('span',{class:{
        'forward-button-text': true,
        'forward-button-text-mobile': _setup.isMobile,
      }},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t('TUIChat.取消'))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }