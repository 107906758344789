var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isLoading)?_c(_setup.SearchResultLoading,{class:['search-result-loading', !_setup.isPC && 'search-result-loading-h5']}):(_setup.isSearchDefaultShow)?_c(_setup.SearchResultDefault,{class:['search-result-default', !_setup.isPC && 'search-result-default-h5']}):_c('div',{class:[
    'tui-search-result',
    !_setup.isPC && 'tui-search-result-h5',
    _setup.isPC && _setup.isResultDetailShow && 'tui-search-result-with-border',
  ]},[(_setup.props.searchType !== 'conversation' && (_setup.isPC || !_setup.isResultDetailShow))?_c('div',{staticClass:"tui-search-result-main"},[_c('div',{staticClass:"tui-search-result-list"},_vm._l((_setup.searchResult),function(result){return _c('div',{key:result.key,staticClass:"tui-search-result-list-item"},[(_setup.props.searchType === 'global')?_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t(`TUISearch.${result.label}`))+" ")]):_vm._e(),_c('div',{staticClass:"list"},_vm._l((result.list),function(item){return _c('div',{key:item.conversation.conversationID,class:[_setup.generateListItemClass(item)]},[(result.key === 'contact' || result.key === 'group' || item.conversation)?_c(_setup.SearchResultItem,{attrs:{"listItem":item,"type":result.key,"displayType":"info","keywordList":_setup.keywordList},on:{"showResultDetail":_setup.showResultDetail,"navigateToChatPosition":_setup.navigateToChatPosition}}):_vm._e()],1)}),0),(_setup.currentSearchTabKey === 'all' || result.cursor)?_c('div',{staticClass:"more",on:{"click":function($event){return _setup.getMoreResult(result)}}},[_c(_setup.Icon,{staticClass:"more-icon",attrs:{"file":_setup.searchIcon,"width":"12px","height":"12px"}}),_c('div',{staticClass:"more-text"},[_c('span',[_vm._v(_vm._s(_setup.TUITranslateService.t("TUISearch.查看更多")))]),_c('span',[_vm._v(_vm._s(_setup.TUITranslateService.t(`TUISearch.${result.label}`)))])])],1):_vm._e()])}),0)]):_vm._e(),(_setup.isResultDetailShow || _setup.props.searchType === 'conversation')?_c('div',{class:[
      'tui-search-result-detail',
      _setup.props.searchType === 'conversation' && 'tui-search-result-in-conversation',
    ]},[(_setup.isSearchDetailLoading)?_c(_setup.SearchResultLoading,{class:['search-result-loading', !_setup.isPC && 'search-result-loading-h5']}):_vm._e(),(!_setup.isSearchDetailLoading && _setup.isResultDetailShow && _setup.props.searchType !== 'conversation')?_c('div',{staticClass:"tui-search-message-header"},[_c('div',{staticClass:"header-content"},[_c('div',{staticClass:"header-content-count normal"},[_c('span',[_vm._v(_vm._s(_setup.searchConversationMessageTotalCount))]),_c('span',[_vm._v(_vm._s(_setup.TUITranslateService.t("TUISearch.条与")))])]),_c('div',{staticClass:"header-content-keyword"},_vm._l((_setup.keywordList),function(keyword,index){return _c('span',{key:index},[_c('span',{staticClass:"normal"},[_vm._v("\"")]),_c('span',{staticClass:"highlight"},[_vm._v(_vm._s(keyword))]),_c('span',{staticClass:"normal"},[_vm._v("\"")])])}),0),_c('div',{staticClass:"header-content-type normal"},[_c('span',[_vm._v(_vm._s(_setup.TUITranslateService.t("TUISearch.相关的")))]),_c('span',[_vm._v(_vm._s(_setup.TUITranslateService.t( `TUISearch.${_setup.currentSearchTabKey === "allMessage" ? "结果" : _setup.searchMessageTypeList[_setup.currentSearchTabKey].label }` )))])])]),_c('div',{staticClass:"header-enter",on:{"click":function($event){return _setup.enterConversation({ conversationID: _setup.currentSearchConversationID })}}},[_c('span',[_vm._v(_vm._s(_setup.TUITranslateService.t("TUISearch.进入聊天")))]),_c(_setup.Icon,{staticClass:"enter-icon",attrs:{"file":_setup.enterIcon,"width":"14px","height":"14px"}})],1)]):_vm._e(),(!_setup.isSearchDetailLoading &&
        _setup.searchConversationMessageList &&
        _setup.searchConversationMessageList[0]
      )?_c('div',{staticClass:"tui-search-message-list"},[(_setup.props.searchType === 'global' ||
          (_setup.currentSearchTabKey !== 'imageMessage' && _setup.currentSearchTabKey !== 'fileMessage')
        )?_vm._l((_setup.searchConversationMessageList),function(item){return _c('div',{key:_setup.generateVueRenderKey(item.ID),class:['list-item']},[_c(_setup.SearchResultItem,{attrs:{"listItem":item,"listItemContent":item.getMessageContent(),"type":_setup.currentSearchTabKey,"displayType":_setup.generateResultItemDisplayType(),"keywordList":_setup.keywordList},on:{"showResultDetail":_setup.showResultDetail,"navigateToChatPosition":_setup.navigateToChatPosition}})],1)}):_vm._l((_setup.searchConversationResultGroupByDate),function(group){return _c('div',{key:_setup.generateVueRenderKey(group.date),class:['list-group', _setup.currentSearchTabKey === 'fileMessage'? 'list-group-file' : 'list-group-image']},[_c('div',{class:['list-group-date']},[_vm._v(" "+_vm._s(group.date)+" ")]),_vm._l((group.list),function(item){return _c('div',{key:_setup.generateVueRenderKey(item.ID),class:['list-group-item']},[_c(_setup.SearchResultItem,{attrs:{"listItem":item,"listItemContent":item.getMessageContent(),"type":_setup.currentSearchTabKey,"displayType":_setup.generateResultItemDisplayType(),"keywordList":_setup.keywordList},on:{"showResultDetail":_setup.showResultDetail,"navigateToChatPosition":_setup.navigateToChatPosition}})],1)})],2)}),(_setup.searchConversationResult && _setup.searchConversationResult.cursor)?_c('div',{staticClass:"more",on:{"click":_setup.getMoreResultInConversation}},[_c(_setup.Icon,{staticClass:"more-icon",attrs:{"file":_setup.searchIcon,"width":"12px","height":"12px"}}),_c('div',{staticClass:"more-text"},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUISearch.查看更多历史记录"))+" ")])],1):_vm._e()],2):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }