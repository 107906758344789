var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isOverlayShow)?_c('div',{ref:"overlayDomRef",staticClass:"overlay-container",style:({
    position: _setup.props.isFullScreen ? 'fixed' : 'absolute',
    zIndex: _setup.props.zIndex,
  })},[(_setup.props.useMask)?_c('div',{class:{
      'overlay-mask': true,
      'fade-in': _setup.props.visible,
    },style:({
      backgroundColor: _setup.props.maskColor,
    }),on:{"click":_setup.onOverlayClick,"touchstart":function($event){$event.preventDefault();$event.stopPropagation();return _setup.onOverlayClick.apply(null, arguments)}}}):_vm._e(),_c('div',{class:{
      'overlay-content': true,
      'full-screen': _setup.props.isFullScreen,
    }},[_vm._t("default")],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }