var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Overlay,{ref:"overlayDomInstanceRef",attrs:{"visible":_setup.props.visible,"useMask":_setup.props.useMask,"maskColor":_setup.props.overlayColor,"isFullScreen":_setup.props.isFullScreen},on:{"onOverlayClick":_setup.onOverlayClick}},[(_setup.isDrawerShow)?_c('div',{ref:"drawerDomRef",class:{
      'drawer': true,
      'origin-bottom': _setup.props.popDirection === 'bottom',
      'origin-right': _setup.props.popDirection === 'right',
      'slide-bottom': _vm.visible && _setup.props.popDirection === 'bottom',
      'slide-right': _vm.visible && _setup.props.popDirection === 'right',
    },style:({
      minHeight: _setup.styles.minHeight,
      maxHeight: _setup.styles.maxHeight,
      borderRadius: _setup.styles.borderRadius,
      boxShadow: _setup.styles.boxShadow,
      width: _setup.styles.width,
    })},[_c('div',{staticClass:"drawer-container"},[_vm._t("default")],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }