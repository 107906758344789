var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_setup.groupApplicationCount > 0)?_c('div',{staticClass:"application-tips"},[_c('div',[_vm._v(" "+_vm._s(_setup.groupApplicationCount)+_vm._s(_setup.TUITranslateService.t("TUIChat.条入群申请"))+" ")]),_c('div',{staticClass:"application-tips-btn",on:{"click":_setup.toggleGroupApplicationDrawerShow}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIChat.点击处理"))+" ")])]):_vm._e(),_c(_setup.Drawer,{ref:"drawerDomInstanceRef",attrs:{"visible":_setup.isGroupApplicationDrawerShow,"zIndex":998,"popDirection":_setup.isMobile ? 'bottom' : 'right',"isFullScreen":_setup.isMobile,"overlayColor":_setup.isMobile ? undefined : 'transparent',"drawerStyle":{
      bottom: {
        minHeight: '60vh',
        maxHeight: '80vh',
        borderRadius: '12px 12px 0 0',
      },
      right: {
        width: '360px',
        borderRadius: '12px 0 0 12px',
        boxShadow: '0 0 10px 0 #d0d0d0',
      }
    }},on:{"onOverlayClick":_setup.toggleGroupApplicationDrawerShow}},[_c('div',{class:{
        'application-contaienr': true
      }},[_c('header',{staticClass:"application-header"},[_c('div',{on:{"click":_setup.toggleGroupApplicationDrawerShow}},[(_setup.isPC)?_c(_setup.Icon,{attrs:{"file":_setup.closeIcon,"size":'16px'}}):_c('div',[_vm._v(" "+_vm._s(_setup.TUITranslateService.t('关闭'))+" ")])],1)]),_c('main',_vm._l((_setup.customGroupApplicationList),function(item,index){return _c('div',{key:item.nick,class:{
            'application-item': true,
            'removed': item.isRemoved,
          }},[_c(_setup.Avatar,{style:({
              flex: '0 0 auto',
            }),attrs:{"url":item.avatar,"useSkeletonAnimation":true}}),_c('div',{staticClass:"application-item-info"},[_c('div',{staticClass:"application-item-nick"},[_vm._v(" "+_vm._s(item.nick)+" ")]),_c('div',{staticClass:"application-item-note"},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIChat.申请加入"))+" ")])]),_c('div',{staticClass:"application-item-operation"},[_c('div',{staticClass:"agree",on:{"click":function($event){return _setup.handleApplication(item, 'Agree', index)}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIChat.同意"))+" ")]),_c('div',{staticClass:"reject",on:{"click":function($event){return _setup.handleApplication(item, 'Reject', index)}}},[_vm._v(" "+_vm._s(_setup.TUITranslateService.t("TUIChat.拒绝"))+" ")])])],1)}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }